.box-wind-coming-section{
    input{
        &.invalid{
            outline: red;
        }
    }
    .text-invalid{
        color: red;
    }
    .text-success{
        color: rgb(11, 177, 11);
    }
    background: #0F172A;
    background-size: cover;
    &.no-1{
        position: relative;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../../assets/images/Langdingbg.png");
        background-size: 100% 132px;
        background-repeat: no-repeat;
        background-position: 0 100%;
        overflow-x: clip;
        overflow: hidden;
        .landing-linear-bg{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            max-height: 130%;
            min-height: 1200px;
        }
        .landing-title{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 5vw;
            line-height: 120%;
            color: #F1F5F9;
        }
        .landing-coming-soon{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #F1F5F9;
            margin-top: 35px;
        }
        .landing-ex-text{
            margin-top: 25px;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #F1F5F9;
        }
        .ex-langding-bg{
            position: absolute;
            top: 0;
            left: 100%;
        }
        .langding-bg-container{
            float: right;
            margin-right: 50px;
            width: fit-content;
        }
        .group-contact-container{
            display: flex;
            column-gap: 16px;
            flex-wrap: nowrap;
            justify-content: center;
            flex-direction: row-reverse;
            input{
                width: 100%;
                background: #64748B;
                border: 1px solid #94A3B8;
                border-radius: 4px;
                padding: 0 32px;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #FFFFFF;
                max-width: 540px;

                &::placeholder{
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 110%;
                    color: #E2E8F0;
                }
            }
            .shared-animation-button{
                span{
                    white-space: nowrap;
                }
            }
        }
    }
}
@media screen and (max-width: 1200px){
    .box-wind-coming-section{
        &.no-1{
            .langding-bg-container{
                float: left;
                margin-right: 0;
            }
            .ex-langding-bg{
                display: none;
            }
        }
    }
}
@media screen and (min-width: 1201px){
    .box-wind-coming-section{
        &.no-1{
            .landing-title{
                font-size: 72px;
            }
        }
    }
}
// 992 768 576
@media screen and (max-width: 768px){
    .box-wind-coming-section{
        &.no-1{
            .text-start{
                margin-top: 120px;
            }
            .langding-bg{
                margin-top: 32px;
                margin-bottom: 32px;
            }
            .landing-title{
                font-size: 54px;
            }
            .langding-bg-container{
                width: 100%;
            }
            .langding-bg{
                width: 100%;
            }
        }
    }
}