.shared-animation-button{
    position: relative;
    // background: #FFFFFF;
    border-radius: 4px;
    padding: 9px 23.5px;
    z-index: 5;
    span{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 110%;
        text-transform: uppercase;
        background: linear-gradient(90deg, #FF2CDF 0.04%, #FF2CDF 0.34%, #0014FF 99.56%, #0014FF 100.07%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    &::before{
        content: "";
        width: 96%;
        height: 100%;
        position: absolute;
        background: #FFFFFF;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 4px;
        transition: all 0.3s;
    }
    &::after{
        content: "";
        width: 96%;
        height: 100%;
        position: absolute;
        background: linear-gradient(90deg, #FF2CDF 0.04%, #FF2CDF 0.34%, #0014FF 99.56%, #0014FF 100.07%);
        top: 12.5%;
        left: 4%;
        z-index: -2;
        border-radius: 4px;
        transition: all 0.3s;
    }
    &:hover::after{
        top: 0;
        left: 0;
    }
}
.text-bg-linear{
    padding: 0 12px;
    background: linear-gradient(90deg, #FF2CDF 0.04%, #FF2CDF 0.34%, #0014FF 99.56%, #0014FF 100.07%);
}