.crypto-info-card{
    background: rgba(15, 23, 42, 0.2);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 14px 16px;
    border: 2px solid;
    border-color: linear-gradient(0deg, rgba(15, 23, 42, 0.4), rgba(15, 23, 42, 0.4)),linear-gradient(90deg, rgba(255, 44, 223, 0.4) 0.04%, rgba(255, 44, 223, 0.4) 0.34%, rgba(0, 20, 255, 0.4) 99.56%, rgba(0, 20, 255, 0.4) 100.07%);
    .crypto-info-card-name-container{
        display: flex;
        flex-direction: column;
        text-align: start;
        margin-left: 8px;
        .crypto-info-card-name{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 110%;
            color: #94A3B8;
        }
        .crypto-info-card-price{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 110%;
            color: #FFFFFF;
        }
    }
    &.flashit{
        background-color: rgba($color: #FFFFFF, $alpha: 0.3);
        -webkit-animation: flash linear 1s;
        animation: flash linear 1s;
    }
    @keyframes flash {
        0% { opacity: 1; } 
        30% { opacity: .1; } 
        80% { opacity: 1; }
        100% { opacity: .1; }
    }
}