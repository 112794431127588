.navbar-brand {
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    font-family: "Poppins",sans-serif;
    padding: 0;
    margin: 0;
}
.collapse{
    visibility: unset !important;
}
.boxwind-nav-coming-soon{
    justify-content: end;
    .navbar-nav{
        .nav-item{
            .nav-link{
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                color: #FFFFFF;
            }
        }
    }
    .navbar-ex{
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 24px;
    }
}
.shrink{
    -webkit-animation: .3s ease-in-out;
    animation: .3s ease-in-out;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    background-color: #0F172A;
}
.navbar{
    transition: all 0.5s;
}
.navbar:has(> .container .navbar-collapse.show){
    background-color: #0F172A;
    height: 100%;
    align-items: self-start;
}
.navbar:has(> .container .collapsing){
    background-color: #0F172A;
    height: 100%;
    align-items: self-start;
}
@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20%, 0);
	}
	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@media screen and (max-width: 992px){
    .navbar-ex{
        margin-top: 24px;
    }
}