.footer-container{
    background: #0F172A;
    .desktop{
        display: block;
    }
    .mobile{
        display: none;
    }
    .image-container{
        width: 24px;
        height: 24px;
        background: #1E293B;
        border: 1px solid #334155;
        position: relative;
        border-radius: 50%;
        cursor: pointer;
        img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
    }
    .footer-info-header{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #E2E8F0;
    }
    .footer-info-list{
        a{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 140%;
            color: #94A3B8;
            text-decoration: none;
            text-align: left;
        }
    }
}
@media screen and (max-width: 768px){
    .footer-container{
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
        .container{
            padding-top: 20px;
        }
        .logo-footer-container{
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            align-items: center;
            .logo-list{
                margin-top: 0;
            }
        }
        .footer-info-header{
            text-align: left;
            color: #E2E8F0;
            font-family: 'Space Grotesk';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
        }
        .footer-info-list{
            a{
                text-align: left;
                color: #94A3B8;
                font-family: 'Space Grotesk';
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 140%; 
            }
        }
    }
}