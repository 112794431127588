.crypto-exchange-info-card{
    display: grid;
    align-items: center;
    grid-template-columns: 120px auto;
    padding: 0 12px;
    div.image-container{
        border-radius: 50%;
        border: 2px solid;
        border-color: linear-gradient(90deg, rgba(255, 44, 223, 0.4) 0.04%, rgba(255, 44, 223, 0.4) 0.34%, rgba(0, 20, 255, 0.4) 99.56%, rgba(0, 20, 255, 0.4) 100.07%);
        background: #1E293B;
        box-shadow: #ce9bcb82 0px 4px 12px;
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 67px;
            height: 67px;
        }
    }
    p{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        color: #F1F5F9;
        margin-left: 16px;
        text-align: left;
    }
}
@media screen and (max-width: 768px){
    .crypto-exchange-info-card{
        // grid-template-columns: none;
        // grid-template-rows: 120px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
            margin-left: 0;
            margin-top: 16px;
            color: #F1F5F9;
            text-align: center;
            font-family: 'Space Grotesk';
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }
    }
}