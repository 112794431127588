.box-wind-section{
    background: #0F172A;
    background-size: cover;
    .desktop{
        display: block;
    }
    .mobile{
        display: none;
    }
    &.no-1{
        position: relative;
        min-height: 730px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../../assets/images/Langdingbg.png");
        background-size: 100% 132px;
        background-repeat: no-repeat;
        background-position: 0 100%;
        overflow-x: clip;
        .landing-linear-bg{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            max-height: 130%;
            min-height: 1200px;
        }
        .welcome-text{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-transform: uppercase;
            color: #F1F5F9;
        }
        .landing-title{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 6vw;
            line-height: 120%;
            color: #F1F5F9;
        }
        .landing-ex-text{
            margin-top: 27px;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #F1F5F9;
        }
        .ex-langding-bg{
            position: absolute;
            top: 0;
            left: 100%;
        }
        .langding-bg-container{
            float: right;
            margin-right: 50px;
            width: fit-content;
        }
    }
    &.no-2{
        padding-bottom: 90px;
        overflow: hidden;
        .view-more-text{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 112%;
            color: #F1F5F9;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
        }
        .crypto-card-container{
            justify-content: center;
        }
    }
    &.no-3{
        background: #142332;
        .exchange-icons-container{
            gap: 0;
        }
    }
    &.no-4{
        .crypto-des-container{
            display: flex;
            flex-direction: column;
            padding-bottom: 32px;
            .crypto-des-total{
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 64px;
                line-height: 68px;
                text-align: center;
                color: #E2E8F0;
            }
            .crypto-unit{
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 110%;
                color: #E2E8F0;
                margin-top: 4px;
            }
            .crypto-des{
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 110%;
                text-align: center;
                color: #E2E8F0;
                margin-top: 8px;
            }
        }
    }
    &.no-5{
        .trade-over-text{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-transform: uppercase;
            color: #FFFFFF
        }
        .trade-crypto-text{
            margin-top: 36px;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 64px;
            text-align: center;
            color: #FFFFFF;
        }
    }
    &.no-6{
        position: relative;
        .trade-over-text{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-transform: uppercase;
            color: #FFFFFF
        }
        .trade-crypto-text{
            margin-top: 36px;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 64px;
            text-align: center;
            color: #FFFFFF;
            max-width: 800px;
        }
        .trade-over-text-des{
            text-align: center;
            font-family: Space Grotesk;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            color: #FFFFFF;
            max-width: 500px;
        }
        .stepper-linear-bg{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            max-height: 180%;
            min-height: 1200px;
            transform: translateY(-40%);
        }
    }
    &.no-7{
        position: relative;
        background-image: url("../../assets/images/CryptoExBg.svg");
        background-size: 100% 100%;
        background-position: 0 100%;
        background-repeat: no-repeat;
        .trade-over-text{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-transform: uppercase;
            color: #FFFFFF
        }
        .trade-crypto-text{
            margin-top: 36px;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 64px;
            text-align: center;
            color: #FFFFFF;
        }
        .exchange-list-container{
            justify-content: center;
            row-gap: 80px;
        }
    }
    &.no-8{
        position: relative;
        background-image: url("../../assets/images/CryptoExBg.svg");
        background-size: 100% 100%;
        background-position: 0 100%;
        background-repeat: no-repeat;
        .trade-over-text{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-transform: uppercase;
            color: #FFFFFF
        }
        .trade-crypto-text{
            margin-top: 36px;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 64px;
            text-align: center;
            color: #FFFFFF;
        }
    }
    &.no-9{
        position: relative;
        background: linear-gradient(90deg, rgba(100, 116, 139, 0.1) 0%, rgba(106, 228, 182, 0.1) 100%), #000000;;
        .trade-over-text{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-transform: uppercase;
            color: #FFFFFF
        }
        .trade-crypto-text{
            margin-top: 36px;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 64px;
            text-align: center;
            color: #FFFFFF;
        }
        .signup-and-yet-text{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: #F1F5F9;
        }
        .group-contact-container{
            display: flex;
            column-gap: 16px;
            flex-wrap: nowrap;
            justify-content: center;
            input{
                width: 100%;
                background: #64748B;
                border: 1px solid #94A3B8;
                border-radius: 4px;
                padding: 0 32px;
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 110%;
                color: #FFFFFF;
                max-width: 540px;
                &::placeholder{
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 110%;
                    color: #E2E8F0;
                }
            }
        }
    }
    &.no-10{
        position: relative;
        overflow: hidden;
        .trade-crypto-text{
            margin-top: 36px;
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 64px;
            text-align: center;
            color: #FFFFFF;
            max-width: 800px;
        }
        .trade-over-text-des{
            text-align: center;
            font-family: Space Grotesk;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            color: #FFFFFF;
            max-width: 500px;
        }
        .stepper-linear-bg{
            position: absolute;
            bottom: 0;
            left: 40px;
            z-index: 1;
            height: 100%;
        }
        .phone-image{
            display: flex;
            justify-content: center;
        }
        .app-dowload-container{
            display: flex;
            gap: 24px;
            justify-content: center;
            img{
                max-width: 50%;
                cursor: pointer;
            }
        }
    }
    &.no-11{
        position: relative;
        .crypto-card-container{
            border-radius: 8px;
            background: linear-gradient(90deg, rgba(100, 116, 139, 0.10) 0%, rgba(106, 228, 182, 0.10) 100%);
            display: flex;
            flex-direction: column;
            align-items: center;
            .text-card-title{
                font-family: 'Space Grotesk';
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 38px;
                text-align: center;
                color: #FFFFFF;
            }
            .text-card-content{
                color: #F1F5F9;
                text-align: center;
                font-family: 'Space Grotesk';
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
            .image-container{
                border-radius: 50%;
                border: 2px solid;
                border-color: linear-gradient(90deg, rgba(255, 44, 223, 0.4) 0.04%, rgba(255, 44, 223, 0.4) 0.34%, rgba(0, 20, 255, 0.4) 99.56%, rgba(0, 20, 255, 0.4) 100.07%);
                background: #1E293B;
                box-shadow: #ce9bcb82 0px 4px 12px;
                width: 120px;
                height: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
}
@media screen and (max-width: 1200px){
    .box-wind-section{
        &.no-1{
            .langding-bg-container{
                float: left;
                margin-right: 0;
            }
            .ex-langding-bg{
                display: none;
            }
        }
    }
}
@media screen and (min-width: 1201px){
    .box-wind-section{
        &.no-1{
            .landing-title{
                font-size: 90px;
            }
        }
    }
}
// 992 768 576
@media screen and (max-width: 768px){
    .box-wind-section{
        .desktop{
            display: none;
        }
        .mobile{
            display: block;
        }
        &.no-1{
            overflow: hidden;
            padding-bottom: 122px;
            .container{
                z-index: 5;
            }
            .text-start{
                margin-top: 120px;
            }
            .langding-bg{
                margin-top: 32px;
                margin-bottom: 32px;
            }
            .landing-title{
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 66px;
                text-align: center;
                margin-top: 4px;
            }
            .welcome-text{
                text-align: center;
                color: #F1F5F9;
                font-family: "Space Grotesk";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                text-transform: uppercase;
            }
            .landing-ex-text{
                text-align: center;
                font-family: 'Space Grotesk';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                color: #F1F5F9;
                max-width: 257px;
                margin-top: 24px;
            }
            .langding-bg-container{
                width: 100%;
                margin-top: 32px;
                .langding-bg{
                    width: 100%;
                    z-index: 2;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            .landing-linear-bg{
                z-index: 4;
                opacity: 1;
                height: 180%;
                width: 140%;
                transform: translateY(-25%) translateX(-23%);
                max-height: 180%;
                max-width: 140%;
                filter: brightness(120%);
            }
            .shared-animation-button{
                width: 100%;
            }
        }
        &.no-2{
            z-index: 5;
            padding-bottom: 80px;
            .box-wind-section-container{
                width: 100%;
                overflow-x: auto;
            }
            .crypto-card-container{
                width: 1200px;
                padding-bottom: 6px;
                .crypto-info-card{
                    border: solid 1px;
                    border-image-slice: 1;
                    border-radius: 8px;
                    border-image-source: linear-gradient(90deg, #FF2CDF 0.04%, #FF2CDF 0.34%, #0014FF 99.56%, #0014FF 100.07%);
                }
            }
            .view-more-text{
                color: #F1F5F9;
                font-family: 'Space Grotesk';
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 112%;
                text-transform: uppercase;
                text-align: right;
            }
        }
        &.no-5{
            padding-top: 0;
            padding-bottom: 140px;
        }
        &.no-3{
            padding-top: 19px;
            padding-bottom: 19px;
            .exchange-icons-container{
                gap: 0;
                row-gap: 25px;
                img{
                    width: 112px;
                }
            }
        }
        &.no-6{
            padding-top: 0;
            overflow: visible;
            padding-bottom: 80px;
            .trade-crypto-text{
                margin-top: 0;
                font-family: "Space Grotesk";
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 38px;
                color: #F1F5F9;
            }
            .trade-over-text-des{
                margin-top: 40px;
                color: #F1F5F9;
                text-align: center;
                font-family: 'Space Grotesk';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; 
            }
            .stepper-linear-bg{
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                max-height: 200%;
                min-height: 1500px;
                transform: translateY(-250px) translateX(0);
            }
            .shared-animation-button{
                margin-top: 0;
                width: 100%;
            }
        }
        &.no-10{
            padding-top: 100px;
            padding-bottom: 0;
            .trade-crypto-text{
                font-family: "Space Grotesk";
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 38px;
            }
            .trade-over-text-des{
                margin-top: 40px;
                color: #F1F5F9;
                text-align: center;
                font-family: 'Space Grotesk';
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
            .phone-image{
                margin-top: 58px;
            }
        }
        &.no-7{
            padding-top: 134px;
            .trade-crypto-text{
                font-family: "Space Grotesk";
                font-size: 36px;
                font-style: normal;
                font-weight: 700;
                line-height: 46px;
            }
        }
        &.no-11{
            padding-top: 0;
            padding-bottom: 40px;
            .crypto-card-container{
                .shared-animation-button{
                    width: 100%;
                }
                .text-card-title{
                    color: #FFF;
                    text-align: center;
                    font-family: 'Space Grotesk';
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px; 
                }
                .text-card-content{
                    color: #F1F5F9;
                    text-align: center;
                    font-family: 'Space Grotesk';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
        }
    }
}