.table-container{
    background: #182837;
    border-radius: 8px;
    border: 2px solid;
    padding: 32px;
    // width: 100%;
    overflow-x: auto;
    .explore-all-text{
        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 110%;
        text-align: center;
        text-transform: uppercase;
        color: #F1F5F9;
        text-align: right;
    }
}

#cryptoTable {
    margin: 0 auto;
    font-size: 1.2em;
    // margin-bottom: 15px;
    border-collapse: collapse;
    border-spacing: 0;
    .table-crypto-title{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 14px;
        span{
            font-family: 'Space Grotesk';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 110%;
            color: #F1F5F9;
        }
    }
    .btn-trade{
        padding: 10px 16px;
        background: #E2E8F0;
        border: 1px solid #1E293B;
        border-radius: 4px;

        font-family: 'Space Grotesk';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 110%;
        color: #1E293B;
        text-align: center;
        text-transform: uppercase;
    }
}

#cryptoTable thead {
    cursor: pointer;
    tr {
        th {
            span{
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 110%;
                color: #94A3B8;
            }
        }
    }
}

#cryptoTable thead tr th{
    font-weight: bold;
    padding: 0 32px 28px 32px;
    
    &:first-child{
        padding-left: 0;
    }
    &:last-child{
        padding-right: 0;
    }
}

#cryptoTable thead tr th span {
    background-repeat: no-repeat;
    background-position: 100% 100%;
}

#cryptoTable thead tr th.headerSortUp,
#cryptoTable thead tr th.headerSortDown {
    background: #acc8dd;
}

#cryptoTable thead tr th.headerSortUp span {
    background-image: url('https://i.imgur.com/SP99ZPJ.png');
}

#cryptoTable thead tr th.headerSortDown span {
    background-image: url('https://i.imgur.com/RkA9MBo.png');
}

#cryptoTable tbody tr {
    color: #555;
}

#cryptoTable tbody tr td {
    &:first-child{
        padding-left: 0;
    }
    &:last-child{
        padding-right: 0;
    }
    text-align: center;
    padding: 4px 32px;
    font-family: 'Space Grotesk';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 110%;
    color: #F1F5F9;
}

#cryptoTable tbody tr td.lalign {
    text-align: left;
}

@media screen and (max-width: 768px){
    .table-container{
        padding: 40px 16px 24px 16px;

        #cryptoTable .table-crypto-title span{
            color: #F1F5F9;
            font-family: 'Space Grotesk';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
        }
        .price-text{
            color: #F1F5F9;
            text-align: right;
            font-family: 'Space Grotesk';
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
        }
        .percent-text{
            color: #FF4A4A;
            text-align: right;
            font-family: 'Space Grotesk';
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
        }
        .mobile-price-container{
            display: table-cell;
        }
        #cryptoTable tbody tr td {
            padding: 8px 17px;
            background: rgba(255, 255, 255, 0.05);
            &:first-child{
                padding-left: 8px;
                border-radius: 8px 0 0 8px;
            }
            &.mobile-price-container{
                padding-right: 8px;
                border-radius: 0 8px 8px 0;
            }
        }
        #cryptoTable{
            border-spacing: 0 8px;
            border-collapse: inherit !important;
        }
    }
}