.box-wind-stepper.ant-steps-horizontal{
    .ant-steps-item-icon{
        .ant-steps-icon{
            div.boxwind-step-icon{
                border-radius: 50%;
                border: 2px solid;
                border-color: linear-gradient(90deg, rgba(255, 44, 223, 0.4) 0.04%, rgba(255, 44, 223, 0.4) 0.34%, rgba(0, 20, 255, 0.4) 99.56%, rgba(0, 20, 255, 0.4) 100.07%);
                background: #1E293B;
                box-shadow: rgba(134, 68, 131, 62%) 0px 4px 17px;
                width: 120px;
                height: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 80px;
                    height: 80px;
                }
            }
        }
    }
    .ant-steps-item-tail{
        top: 50px;
        left: 10px;
        &::after{
            background: linear-gradient(90.01deg, rgba(106, 228, 182, 0.4) -0.16%, rgba(13, 214, 246, 0.4) 27.01%, rgba(1, 85, 255, 0.4) 50.52%, rgba(252, 0, 122, 0.4) 72.46%, rgba(250, 187, 161, 0.4) 100.15%);
        }
    }
    .ant-steps-item-content{
        margin-top: 80px;
        margin-bottom: 120px;
        width: 200px;
        max-width: 80%;
        position: relative;
        .ant-steps-item-title{
            .boxwind-step-title{
                font-family: 'Space Grotesk';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 140%;
                text-align: center;
                color: #E2E8F0;
                z-index: 5;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.no-1::after{
                    content: "01";
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 160px;
                    line-height: 140%;
                    text-align: center;
                    color: #616e83;
                    opacity: 0.2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    z-index: 0;
                }
                &.no-2::after{
                    content: "02";
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 160px;
                    line-height: 140%;
                    text-align: center;
                    color: #616e83;
                    opacity: 0.2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    z-index: 0;
                }
                &.no-3::after{
                    content: "03";
                    font-family: 'Space Grotesk';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 160px;
                    line-height: 140%;
                    text-align: center;
                    color: #616e83;
                    opacity: 0.2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    z-index: 0;
                }
            }
        }
        .ant-steps-item-description{
            color: #FFF !important;
            text-align: center;
            font-family: 'Space Grotesk';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;

            position: absolute;
            top: 0;
            transform: translateY(110px);
        }
    }
}

.box-wind-stepper.ant-steps-vertical{
    .ant-steps-item-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }
    .ant-steps-item{
        padding-bottom: 56px;
        &:last-child{
            padding-bottom: 0;
        }
    }
    .ant-steps-item-icon{
        margin-inline-end: 0 !important;
        position: relative;
        transform: translateX(calc(-50% - 20px));
        .ant-steps-icon{
            div.boxwind-step-icon{
                border-radius: 50%;
                border: 2px solid;
                border-color: linear-gradient(90deg, rgba(255, 44, 223, 0.4) 0.04%, rgba(255, 44, 223, 0.4) 0.34%, rgba(0, 20, 255, 0.4) 99.56%, rgba(0, 20, 255, 0.4) 100.07%);
                background: #1E293B;
                box-shadow: rgba(134, 68, 131, 62%) 0px 4px 17px;
                width: 120px;
                height: 120px;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
    .ant-steps-item-tail{
        display: none !important;
        top: 10px !important;
        inset-inline-start: 62px !important;
        &::after{
            background: linear-gradient(90.01deg, rgba(106, 228, 182, 0.4) -0.16%, rgba(13, 214, 246, 0.4) 27.01%, rgba(1, 85, 255, 0.4) 50.52%, rgba(252, 0, 122, 0.4) 72.46%, rgba(250, 187, 161, 0.4) 100.15%);
        }
    }
    
    .ant-steps-item-content{
        height: 170px;
        max-height: 265px;
        overflow: visible;
        display: flex !important;
        justify-content: end;
        align-items: center;
        flex-direction: column;
        .ant-steps-item-title{
            padding-inline-end: 0;
            .boxwind-step-title{
                text-align: center;
                font-family: 'Space Grotesk';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; 
                color: #E2E8F0;
                z-index: 5;
                &.no-1::after{
                    content: "01";
                    font-family: 'Space Grotesk';
                    font-size: 128px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    text-align: center;
                    color: #616e83;
                    opacity: 0.2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    z-index: 0;
                }
                &.no-2::after{
                    content: "02";
                    font-family: 'Space Grotesk';
                    font-size: 128px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    text-align: center;
                    color: #616e83;
                    opacity: 0.2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    z-index: 0;
                }
                &.no-3::after{
                    content: "03";
                    font-family: 'Space Grotesk';
                    font-size: 128px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    text-align: center;
                    color: #616e83;
                    opacity: 0.2;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    z-index: 0;
                }
            }
        }
        .ant-steps-item-description{
            color: #F1F5F9 !important;
            text-align: center;
            font-family: Space Grotesk;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;

            padding: 0 24px 0 24px;
            margin-top: 60px;
        }
    }
}